import React from 'react'

import styles from './CAMPS.module.css'

export default () => 
(
  <div>
    <p>
        We are thankful that we can be involved in the spreading of food parcels, especially during the time of the Covid-19 pandemic. It
        gives us the opportunity to show God’s love through caring for people in need. A gospel tract is also included in the food parcels.
    </p>    
    <p>
        Vegetable garden “starter kits” for children are distributed in the communities. The kits contain instructions, different vegetable
        seeds and some potting soil. A tract with the Gospel message and a Bible story are also included.
    </p>
    <p>
        We are also involved at some soup kitchens in Sir Lowry’s Pas Village. The children receive food and also a short message daily.
        Some needy families are supported on a regular basis through funds that has been donated for specific needs.
    </p>    
  </div>
)
