import React from 'react'

import styles from './BIBLE-CLUBS.module.css'

export default () => 
(
  <div>
    <p>
    We are thankful for the more than 1800 children that are being reached weekly in the different
    Bible clubs that we run in communities, schools, pre-primary schools, aftercare centres and on farms
    in the Helderberg basin area.
    </p>
    <figure class="quote">
    <blockquote>
        Statistics show that
        more than <strong>80%</strong> of
        people who follow Christ,
        does that before
        the age of 14 years. It
        is therefore very
        strategic to
        focus specifically on
        this age group.
    </blockquote>            
    </figure>
    <p>
        Many of the children come from poor communities with many challenges such as violence,
        alcoholism, drugs and negative peer pressure.
    </p>
    <p>
        We pray that children can be mentored to grow spiritually and develop Godly characters in
        order to be spiritually resilient and to stand strong in the midst of challenging
        circumstances.
    </p>
    <u><strong>Children from some of the Bible Clubs says:</strong></u>
    <ul>
        <li>
            “I used to think that God was a fairy tale, but now I know better”<br/>
            “I’m now a believer”
        </li>
        <li>
            “You have encouraged me to pray more and not get worried that someone will laugh and I can just be free to talk to God.”
        </li>
        <li>
            “The stories you have taught made the Bible 10 times more clear for me… I will remember it till my heart stops pumping.”
        </li>
        <li>
            “Thank you for teaching us about God and the miracles He does and the way He sacrificed His own Son for us and His love.”
        </li>
        <li>
            “I learned a lot of things, I came closer to God.”
        </li>
        <li>
            “Thank you for teaching us how to use a Bible properly.”
        </li>
        <li>
            “I wasn’t very interested in God but now I am.”
        </li>
        <li>
            “My father says that God doesn’t exist – but I believe in Him.”
        </li>
        <li>
            “The coming holiday I’m going to take up the challenge and read the Bible when I wake up instead of
            watching TV….”
        </li>
    </ul>
  </div>
)
